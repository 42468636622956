.container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-left: 13px;
    margin: 0;
    width: 80mm;
}

.container p {
    margin: 0;
    font-family: "Anton" !important;
    font-weight: "normal" !important;
    font-style: "normal" !important;
    font-size: 12px;
    margin: 0;
}

.container hr {
    border: none;
    border-top: 1px dotted #000;
    color: #fff;
    background-color: #fff;
    width: 100%;
    margin: 0;
}

.line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
}

.lineTwo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin: 0;
}

.line p,
.lineTwo p {
    flex: 1;
    word-wrap: break-word;
    white-space: normal;
    font-weight: bolder;
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Limita a exibição a 3 linhas */
    -webkit-box-orient: vertical;
    margin: 0;
}


.lineTwo p:nth-child(2) {
    /* Refere-se à coluna do nome do produto */
    flex: 2;
    font-size: 12px;
    font-weight: bolder;
    margin: 0;
    /* Torna a coluna levemente mais larga */
}

.lineTwo p:nth-child(3) {
    /* Refere-se à coluna de QTDE */
    flex: 1;
    /* Reduz a largura da coluna */
    min-width: 30px;
    /* Define uma largura mínima para evitar que fique muito pequena */
    text-align: center;
    /* Alinha o texto centralizado */
    margin: 0;
}

.lineTwo p:nth-child(4) {
    /* Refere-se à coluna de QTDE */
    flex: 0.5;
    /* Reduz a largura da coluna */
    min-width: 30px;
    /* Define uma largura mínima para evitar que fique muito pequena */
    text-align: center;
    /* Alinha o texto centralizado */
    margin: 0;
}